<template>
  <div class="bigBox">
    <div class="tab-colleges">
      <div class="tab-item" :class="[cardStates == 'all' ? 'active' : '']">
        <div class="tab-text" @click="navChange('all')">全部</div>
        <div class="tab-line"></div>
      </div>
      <div class="tab-item" :class="[cardStates == 0 ? 'active' : '']">
        <div class="tab-text" @click="navChange(0)">已激活</div>
        <div class="tab-line"></div>
      </div>
      <div class="tab-item" :class="[cardStates == 1 ? 'active' : '']">
        <div class="tab-text" @click="navChange(1)">已过期</div>
        <div class="tab-line"></div>
      </div>
      <div class="tab-item" :class="[cardStates == 2 ? 'active' : '']">
        <div class="tab-text" @click="navChange(2)">学习卡激活</div>
        <div class="tab-line"></div>
      </div>
    </div>
    <!-- 学习卡列表 -->
    <div class="contentBox" v-show="cardStates == 'all' || cardStates == 0 || cardStates == 1">
      <div class="cardItem" v-for="(item, index) in list" :key="index">
        <div class="itemBox">
          <img class="itemImg" :src="item.img" />
          <div v-if="item.label == 'nomal'" class="label">已激活</div>
          <div v-if="item.label == 'dead'" class="labelExpired">已过期</div>
          <div class="contentItem">
            <div class="contentTitle">
              {{ item.title ? item.title : item.tiitle }}
            </div>
            <div class="tips">
              {{ liveEnum[item.types] ? liveEnum[item.types] : "知识套餐" }}
            </div>
            <div class="time">到期时间：{{ item.deadTimes }}</div>
          </div>
        </div>
        <div class="colleges-learn" @click="goDetail(item)">立即学习</div>
      </div>
    </div>
    <!-- 学习卡激活 -->
    <div class="activation" v-show="cardStates == 2">
      <div class="activationBox">
        <el-form class="colleges-form">
          <el-form-item label="卡号" label-width="60px">
            <el-input class="inputItem" placeholder="请输入卡号" v-model="cardCode"></el-input>
          </el-form-item>
          <el-form-item label="密码" label-width="60px">
            <el-input class="inputItem" placeholder="请输入密码" v-model="cardPass"></el-input>
          </el-form-item>
        </el-form>
        <div class="errorTips" v-show="errTipsInfo">{{ errorTips }}</div>
        <el-button :disabled="btnDisabled" class="activationBtn" @click="submit">确认激活</el-button>
      </div>
    </div>
    <el-dialog class="error-dialog" :visible.sync="dialogVisible" width="360px" :before-close="handleClose">
      <div class="dialogBox">
        <img class="dialogImg" src="@/assets/img/Home/Group 465.png" />
        <div class="dialogTips">激活成功！</div>
        <div class="className">
          {{ activeStudyCardtInfo ? activeStudyCardtInfo.productName : "" }}
        </div>
        <div class="time">
          到期时间：{{
            activeStudyCardtInfo ? activeStudyCardtInfo.deadTime : ""
          }}
        </div>
        <div class="colleges-learn" @click="handleClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getActStudyProductList, activeStudyCardt } from "@/api/home.js";
import { liveEnum } from "@/api/emun.js";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
export default {
  data() {
    return {
      cardStates: "all",
      cardCode: "", // 卡号
      cardPass: "", // 卡密
      dialogVisible: false,
      activeStudyCardtInfo: null, // 激活返回状态  是否成功
      errTipsInfo: false,
      nomalProdList: [], // 已激活列表
      deadProdList: [], // 已过期列表
      list: [], // 渲染的列表
      liveEnum: null,
      errorTips: null,
      btnDisabled: false,
    };
  },
  created() {
    this.liveEnum = liveEnum;
  },
  mounted() {
    this.getActStudyProductList();
  },
  methods: {
    navChange(val) {
      this.list = [];
      let arr = [];
      if (val == "all") {
        if (this.nomalProdList.length > 0) {
          arr.push(...this.nomalProdList);
        }
        if (this.deadProdList.length > 0) {
          arr.push(...this.deadProdList);
        }
      } else if (val == 0) {
        if (this.nomalProdList.length > 0) {
          arr.push(...this.nomalProdList);
        } else {
          this.list = [];
        }
      } else if (val == 1) {
        if (this.deadProdList.length > 0) {
          arr.push(...this.deadProdList);
        } else {
          this.list = [];
        }
      }
      this.list = arr;

      this.cardStates = val;
      this.pageSize = 8;
      this.pageNum = 1;
    },
    /* 学习卡下套餐列表 */
    getActStudyProductList() {
      let data = {
        userId: getInfo().id,
      };
      getActStudyProductList(data).then((res) => {
        if (res.code == 0 && res.msg) {
          this.nomalProdList = res.msg.nomalProdList;
          this.deadProdList = res.msg.deadProdList;
          this.list.push(...res.msg.nomalProdList, ...res.msg.deadProdList);
        }
      });
    },
    /* 确认激活 */
    submit() {
      this.btnDisabled = true;
      let data = {
        cardCode: this.cardCode,
        cardPass: this.cardPass,
      };
      activeStudyCardt(data).then((res) => {

        if (res.code == 0) {
          this.errTipsInfo = false;
          this.activeStudyCardtInfo = res.msg;
          this.dialogVisible = true;
          this.getActStudyProductList();
          this.btnDisabled = false;
        } else {
          this.errorTips = res.msg;
          this.errTipsInfo = true;
          this.btnDisabled = false;
        }
      });
    },
    goDetail(item) {
      let type = null;
      if (item.types == 1) {
        type = 2;
      } else if (item.types == 2) {
        type = 3;
      } else if (item.types == 3) {
        type = 7;
      } else if (item.types == 4) {
        type = 8;
      } else if (item.types == 5) {
        type = 10;
      }
      if (item.type != 0) {
        Vue.prototype.goTypeDetail(
          type,
          item.id,
          undefined,
          undefined,
          undefined,
          undefined,
          item.cardCode
        );
      } else {
        if (item.productLine == "3") {
          this.$router.push({
            path: `/VIP/VIPClassxq?id=${item.id}&cardCode=${item.cardCode}`,
          });
        } else {
          this.$router.push({
            path: `/typeclassxq?id=${item.id}&cardCode=${item.cardCode}`,
          });
        }
      }
    },
    /* 关闭弹窗回调 */
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.bigBox {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0px 24px 20px;

  .contentBox {
    width: 100%;
    margin-top: 24px;

    .cardItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .itemBox {
        display: flex;
        position: relative;

        .itemImg {
          width: 192px;
          height: 128px;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          margin-right: 12px;
          flex-shrink: 0;
        }

        .contentItem {
          .contentTitle {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
          }

          .tips {
            margin-top: 16px;
            height: 20px;
            background: #fff2e9;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ff7b1c;
            line-height: 20px;
            padding: 0px 8px;
            display: inline-block;
          }

          .time {
            margin-top: 20px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }

        .label {
          position: absolute;
          top: 0;
          left: 0;
          width: 62px;
          height: 24px;
          background: #45b757;
          border-radius: 8px 0 8px 0;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
          line-height: 24px;
        }

        .labelExpired {
          position: absolute;
          top: 0;
          left: 0;
          width: 62px;
          height: 24px;
          background: #999999;
          border-radius: 8px 0 8px 0;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
          line-height: 24px;
        }
      }
    }
  }

  .activation {
    width: 100%;

    .activationBox {
      margin-top: 24px;

      .activationBtn {
        width: 100px;
        height: 36px;
        background: #4a6af0;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        padding: 0px !important;
        margin-left: 124px;
      }

      .inputItem {
        width: 480px;
      }

      .errorTips {
        color: #f33232;
        font-size: 14px;
        margin-left: 60px;
        margin-bottom: 40px;
      }
    }
  }
}

/deep/ .error-dialog {
  display: flex;
  align-items: center;
  justify-content: center;

  .el-dialog {
    width: 360px;
    /* height: 306px; */
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;

    .el-dialog__header {
      display: none;
    }
  }
}

.dialogBox {
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  .dialogImg {
    width: 60px;
    height: 60px;
    background: linear-gradient(138deg, #7ae8a6 0%, #52cd83 100%);
    opacity: 1;
    border-radius: 50%;
  }

  .dialogTips {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    margin-top: 16px;
  }

  .className {
    margin-top: 32px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    white-space: pre-wrap;
  }

  .time {
    margin-top: 16px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }

  .colleges-learn {
    width: 80px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #4a6af0;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #4a6af0;
    line-height: 40px;
    margin-top: 32px;
    text-align: center;
  }
}
</style>